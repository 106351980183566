import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

const PostTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark
  let { previous, next } = pageContext

  const homePage = {
    slug: "/",
    title: "Home"
  }

  previous = previous || homePage
  next = next || homePage

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt || post.excerpt}
      />
      <div className="p-8">
        <article className="shadow-2xl max-w-2xl mx-auto">
          <Post
            className="post-single"
            imageFluid={post.frontmatter.image.childImageSharp.fluid}
            imageAlt={post.frontmatter.imageAlt || post.frontmatter.title}
            date={post.frontmatter.date}
            title={post.frontmatter.title}
            html={post.html}
          />
        </article>

        <nav className="pt-4">
          <ul className="flex max-w-2xl mx-auto justify-between content-between">
            {previous && (
              <li className={`w-full ${next ? "mr-4" : ""}`}>
                <Link
                  to={previous.slug}
                  rel="prev"
                  className="inline-block bg-gray-900 text-gray-100 w-full h-full text-center p-4 hover:bg-gray-700"
                >
                  ← {previous.title}
                </Link>
              </li>
            )}
            {next && (
              <li className="w-full">
                <Link
                  to={next.slug}
                  rel="next"
                  className="inline-block bg-gray-900 text-gray-100 w-full h-full text-center p-4 hover:bg-gray-700"
                >
                  {next.title} →
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        excerpt
        image {
          childImageSharp {
            fluid(maxWidth: 700, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageAlt
      }
    }
  }
`
